<template>
  <v-card>
    <v-form ref="user_reset_password" @submit.prevent="handleResetPassword">
      <v-card-title class="text-h5" primary-title>
        <h6 class="text-h6">
          {{ $trans("reset_password") }}
        </h6>
      </v-card-title>
      <v-divider />
      <v-card-text
        v-if="
          (userIsAdmin && !loggedUserIsAdmin) ||
          (userIsRoot && !loggedUserIsRoot)
        "
      >
        <calendesk-information-message
          color="orange"
          additional-class="white--text my-4"
          icon-color="white"
          icon="$alert"
        >
          {{ $trans("you_can_edit_admin_password_info") }}
        </calendesk-information-message>
      </v-card-text>
      <v-card-text v-else>
        <v-container>
          <v-row>
            <template v-if="user && user.email">
              <v-col class="pl-0 pr-0">
                <p class="text-subtitle-1">
                  {{ $trans("generate_new_password_automatically") }}
                </p>
              </v-col>
              <v-col cols="2">
                <v-switch v-model="generatePassword" :disabled="isLoading" />
              </v-col>
              <v-col v-if="!generatePassword" cols="12">
                <v-text-field
                  v-model="password"
                  type="password"
                  validate-on-blur
                  :rules="[rules.required, rules.minChars(8)]"
                  :label="$trans('password')"
                  outlined
                  class="password"
                  color="secondary"
                  autocomplete="new-password"
                />
              </v-col>
            </template>
            <v-col v-else class="pl-0 pr-0">
              <p class="text-subtitle-1">
                {{ $trans("no_email_for_password_reset_info") }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="light" text @click="$emit('close')">
          {{ $trans("cancel") }}
        </v-btn>
        <v-btn
          v-if="
            user &&
            user.email &&
            !(
              (userIsAdmin && !loggedUserIsAdmin) ||
              (userIsRoot && !loggedUserIsRoot)
            )
          "
          color="accent_light"
          text
          :disabled="isLoading || isActive"
          type="submit"
        >
          {{ $trans("ok") }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import {
  minChars,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import roleActions from "@/calendesk/mixins/roleActions";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage";

export default {
  name: "SetNewPasswordDialog",
  components: { CalendeskInformationMessage },
  mixins: [roleActions],
  data() {
    return {
      isLoading: false,
      rules: {
        required,
        minChars,
      },
      generatePassword: true,
      password: null,
      user: null,
      sendLinkForEmployees: false,
    };
  },
  computed: {
    isActive() {
      if (this.generatePassword) {
        return false;
      }
      const condition = this.password && this.password.length >= 8;
      return !condition;
    },
    userIsAdmin() {
      return this.$helpers.userHasRole(
        this.user,
        this.$helpers.roleSlugs.admin
      );
    },
    userIsRoot() {
      return this.$helpers.userHasRole(this.user, this.$helpers.roleSlugs.root);
    },
  },
  created() {
    this.user = this.getConfirmDialog.data.user;
    this.sendLinkForEmployees = this.getConfirmDialog.data.sendLinkForEmployees;
  },
  methods: {
    ...mapActions({
      updateUser: "user/update",
    }),
    handleResetPassword() {
      if (this.$refs.user_reset_password.validate()) {
        this.$emit("loading", true);
        this.isLoading = true;
        const data = {
          id: this.user.id,
        };

        if (this.generatePassword) {
          data.send_reset_password = 1;
          data.in_admin = this.sendLinkForEmployees ? 1 : 0;
        } else {
          data.password = this.password;
        }

        this.updateUser(data)
          .then(() => {
            const text = this.generatePassword
              ? "reset_password_sent_email"
              : "reset_password_success_title";
            this.$emit("loading", false);
            this.isLoading = false;
            this.$emit("close");
            successNotification(text);
          })
          .catch((error) => {
            this.$emit("loading", false);
            this.isLoading = false;
            this.$emit("close");
            errorNotification(null, error);
          });
      }
    },
  },
};
</script>
