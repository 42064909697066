var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-form',{ref:"user_reset_password",on:{"submit":function($event){$event.preventDefault();return _vm.handleResetPassword.apply(null, arguments)}}},[_c('v-card-title',{staticClass:"text-h5",attrs:{"primary-title":""}},[_c('h6',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$trans("reset_password"))+" ")])]),_c('v-divider'),(
        (_vm.userIsAdmin && !_vm.loggedUserIsAdmin) ||
        (_vm.userIsRoot && !_vm.loggedUserIsRoot)
      )?_c('v-card-text',[_c('calendesk-information-message',{attrs:{"color":"orange","additional-class":"white--text my-4","icon-color":"white","icon":"$alert"}},[_vm._v(" "+_vm._s(_vm.$trans("you_can_edit_admin_password_info"))+" ")])],1):_c('v-card-text',[_c('v-container',[_c('v-row',[(_vm.user && _vm.user.email)?[_c('v-col',{staticClass:"pl-0 pr-0"},[_c('p',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$trans("generate_new_password_automatically"))+" ")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-switch',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.generatePassword),callback:function ($$v) {_vm.generatePassword=$$v},expression:"generatePassword"}})],1),(!_vm.generatePassword)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"password",attrs:{"type":"password","validate-on-blur":"","rules":[_vm.rules.required, _vm.rules.minChars(8)],"label":_vm.$trans('password'),"outlined":"","color":"secondary","autocomplete":"new-password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1):_vm._e()]:_c('v-col',{staticClass:"pl-0 pr-0"},[_c('p',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$trans("no_email_for_password_reset_info"))+" ")])])],2)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"light","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$trans("cancel"))+" ")]),(
          _vm.user &&
          _vm.user.email &&
          !(
            (_vm.userIsAdmin && !_vm.loggedUserIsAdmin) ||
            (_vm.userIsRoot && !_vm.loggedUserIsRoot)
          )
        )?_c('v-btn',{attrs:{"color":"accent_light","text":"","disabled":_vm.isLoading || _vm.isActive,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$trans("ok"))+" ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }